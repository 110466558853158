.dhx_diagram {
    background: #fff;
}

.dhx_sample-container__without-editor {
    height: calc(100% - 121px);
}

.dhx_sample-container__with-editor {
    height: calc(100% - 61px);
    height: 1200px;
    width: auto;
}

.dhx_sample-widget {
    height: 100%;
}

.dhx_sample-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60px;
    height: 60px;
    border-bottom: 1px solid #FFFF;
    font-family: Roboto, Arial, Tahoma, Verdana, sans-serif;
    font-size: 14px;
}

.dhx_sample-controls button:not(:last-child),
.dhx_sample-controls input:not(:last-child),
.dhx_sample-controls select:not(:last-child) {
    margin-right: 12px;
}

.dhx_sample-controls .dhx_form-group {
    margin: 0;
}

.dhx_sample-controls .dhx_form-group .dhx_label {
    padding-left: 6px;
    line-height: 24px;
    max-width: 100%;
}

.dhx_sample-btn {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: none;
    outline: none;
    padding: 6px 16px;
    border-radius: 2px;
    font-family: Roboto, Arial, Tahoma, Verdana, sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    text-transform: uppercase;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    background-color: #61dafb;
    color: white;
}


.dhx-diagram-demo_network-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #f7f7f7;
    width: 100%;
    height: 100%;
    border: 1px solid #dfdfdf;
    overflow: hidden;
}

.dhx-diagram-demo_network-card span {
    font-weight: 300;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    text-align: center;
}

.dhx-diagram-demo_network-card span:not(:last-child) {
    font-weight: 500;
    margin-top: 16px;
}

.dhx-diagram-demo_network-card img {
    width: 64px;
    height: 64px;
    pointer-events: none;
    border-radius: 50%;
}