 :root {
     --hover-color1: #c7755c;
     --hover-color2: #faf8f8;
     --ripple-color: rgba(20, 20, 20, 0.4);
     --confetti-color1: #ffcf5c;
     --confetti-color2: #75d5c0;
     --confetti-color3: #f97aa3;
     --tealcolor: #017d7c;
 }



 .mic-container {
     position: relative;
     border-radius: 50%;
     display: flex;
     align-items: center;
     justify-content: center;
     transition: transform 0.3s ease;
     cursor: pointer;
     overflow: visible;
 }

 /* Icon mic */
 .mic-container i {
     font-size: 24px;
     z-index: 10;
     animation: mic-zoom 2s infinite ease-in-out;
     /* Durée de 2s pour un mouvement fluide */
 }

 /* Circles animation */
 .mic-container .circle {
     position: absolute;
     border: 2px solid var(--tealcolor);
     border-radius: 50%;
     animation: circle-pulse 2.5s infinite ease-out;
     /* Durée ajustée pour plus de fluidité */
     opacity: 0;
 }

 .mic-container .circle:nth-child(1) {
     width: 60px;
     height: 60px;
     animation-delay: 0s;
 }

 .mic-container .circle:nth-child(2) {
     width: 50px;
     height: 50px;
     animation-delay: 0.8s;
 }

 .mic-container .circle:nth-child(3) {
     width: 50px;
     height: 50px;
     animation-delay: 1.6s;
 }

 /* Animations */
 @keyframes mic-zoom {
     0% {
         transform: scale(0.8);
         /* Dézoom plus prononcé */
     }

     50% {
         transform: scale(1.2);
         /* Zoom plus prononcé */
     }

     100% {
         transform: scale(0.8);
         /* Retour au dézoom */
     }
 }

 @keyframes circle-pulse {
     0% {
         transform: scale(0.8);
         opacity: 0.6;
     }

     70% {
         transform: scale(1.2);
         opacity: 0.2;
     }

     100% {
         transform: scale(1.5);
         opacity: 0;
     }
 }

 /*
.ripple-container {
    position: relative;
    border-radius: 50%;
    background-color: var(--hover-color1);
    animation: ripple 2s infinite, color-fade 2s infinite alternate;
    overflow: hidden;
    width: 80px;
    height: 80px;
}


@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 var(--ripple-color);
        transform: scale(1);
    }

    50% {
        box-shadow: 0 0 0 20px var(--ripple-color);
        transform: scale(1.1);
    }

    100% {
        box-shadow: 0 0 0 40px rgba(20, 20, 20, 0);
        transform: scale(1);
    }
}


@keyframes color-fade {
    0% {
        background-color: var(--hover-color1);
    }

    100% {
        background-color: var(--hover-color2);
    }
}


.confetti {
    position: absolute;
    width: 5px;
    height: 10px;
    background-color: var(--confetti-color1);
    opacity: 0;
    transform: scale(0) rotate(0);
    animation: confetti-fall 2s ease-out forwards;
}

.confetti:nth-child(2) {
    background-color: var(--confetti-color2);
    animation-delay: 0.1s;
}

.confetti:nth-child(3) {
    background-color: var(--confetti-color3);
    animation-delay: 0.2s;
}

.confetti:nth-child(4) {
    background-color: var(--confetti-color1);
    animation-delay: 0.3s;
}

.confetti:nth-child(5) {
    background-color: var(--confetti-color2);
    animation-delay: 0.4s;
}


@keyframes confetti-fall {
    0% {
        opacity: 1;
        transform: scale(1) translateY(-50px) rotate(0);
    }

    100% {
        opacity: 0;
        transform: scale(0.5) translateY(50px) rotate(360deg);
    }
} */