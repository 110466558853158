@keyframes drawBox {
    0% {
        stroke-dashoffset: 500;
    }

    40% {
        stroke-dashoffset: 0;
    }

    60% {
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes drawArrow {
    0% {
        stroke-dashoffset: 200;
    }

    40% {
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}