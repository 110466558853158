@tailwind base;
@tailwind components;
@tailwind utilities;


.rotate-icon {
    transition: transform 0.3s ease-in-out;
}

#analyseButton:hover .rotate-icon {
    transform: rotate(360deg);
}